<template>
  <router-layout>
    <div class="page noscroll">
      <div class="tohome" @click="toHome" v-show="home"></div>
      <div class="head" ref="head">
        <van-search placeholder="请输入搜索内容" readonly @click="toSearch" />
      </div>
      <div class="pageMain">
        <van-swipe :autoplay="3000" class="swiper">
          <van-swipe-item v-for="(item, index) in swiper" :key="index">
            <img :src="item.image" />
          </van-swipe-item>
        </van-swipe>
        <div class="dropmenu">
          <van-dropdown-menu z-index="100">
            <van-dropdown-item
              :title="typeTitle"
              v-model="houseType"
              :options="type"
              @open="menuOpen1"
              @closed="menuClose1"
              @change="typeChange"
            />
            <van-dropdown-item
              :title="areaTitle"
              v-model="houseArea"
              :options="area"
              @open="menuOpen2"
              @closed="menuClose2"
              @change="areaChange"
            />
            <van-dropdown-item
              :title="decorationTitle"
              v-model="houseDecoration"
              :options="decoration"
              @open="menuOpen3"
              @closed="menuClose3"
              @change="decorationChange"
            />
          </van-dropdown-menu>
        </div>
        <div class="listbd">
          <van-loading
            color="#1989fa"
            type="spinner"
            class="dataLoading"
            v-if="$store.state.dataLoading"
          />
          <div v-else>
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              offset="10"
              v-show="hasData"
            >
              <ul v-show="hasData">
                <li v-for="(item,index) in List" :key="index" @click="toDetail(item)">
                  <div class="listL" :style="{ 'background': 'url(' + item.image + ')'}"></div>
                  <div class="listR">
                    <div>
                      <h2>{{item.title}}</h2>
                      <h3>{{item.address}}</h3>
                    </div>
                    <div class="btdiv">
                      <span class="or">{{item.money}}</span>
                      <span class="area">{{item.area}}㎡</span>
                    </div>
                  </div>
                </li>
              </ul>
            </van-list>
            <van-empty description="暂无内容" v-show="!hasData" />
          </div>
        </div>
      </div>
    </div>
  </router-layout>
</template>
<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 0,
      hasData: true, //判断有无数据
      home: true,
      drop1: false,
      drop2: false,
      drop3: false,
      searchvalue: "",
      swiper: [{ image: require("@/assets/images/banner1.jpg"), url: "" }],
      typeTitle: "房源类型",
      areaTitle: "面积范围",
      decorationTitle: "装修程度",
      houseType: "",
      houseArea: "",
      houseDecoration: "",
      type: [
        { text: "商铺", value: "1" },
        { text: "住宅", value: "2" },
        { text: "写字楼", value: "3" },
        { text: "厂房", value: "4" },
        { text: "仓库", value: "5" }
      ],
      area: [
        { text: "不限", value: "0" },
        { text: "50m²以下", value: "50" },
        { text: "50m²~80m²", value: "50-80" },
        { text: "81m²~120m²", value: "81-120" },
        { text: "121m²~180m²", value: "121-180" },
        { text: "181m²~300m²", value: "181-300" },
        { text: "301m²~500m²", value: "301-500" },
        { text: "501m²~1000m²", value: "501-1000" },
        { text: "1001m²~2000m²", value: "1001-2000" },
        { text: "2001m²以上", value: "2001" }
      ],
      decoration: [
        { text: "不限", value: "0" },
        { text: "毛坯", value: "1" },
        { text: "简装", value: "2" },
        { text: "精装", value: "3" }
      ],
      typeSelect: "",
      areaSelect: [],
      decorationSelect: "",
      List: [], //所有数据列表
      typeList: [], //房源类型筛选数据
      areaList: [], //面积范围筛选数据
      decorationList: [] //装修程度筛选数据
    };
  },
  mixins: [authMixin],
  created() {
    this.$store.commit("setDataLoading", true);
    this.getAllData();
  },
  watch: {},
  methods: {
    Back() {
      this.$router.back();
    },
    typeChange(val) {
      //房源类型选择
      this.typeTitle = "";
      this.typeSelect = val;
      this.page = 0;
      this.List = [];
      this.finished = false
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    areaChange(val) {
      //面积范围选择
      this.areaTitle = "";
      if (val == "0") {
        this.areaSelect = [];
      } else if (val == "50") {
        this.areaSelect = [0, 50];
      } else if (val == "2001") {
        this.areaSelect = [2001, 1000000000];
      } else {
        this.areaSelect = val.split("-");
      }
      this.page = 0;
      this.List = [];
      this.finished = false
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    decorationChange(val) {
      //装修程度选择
      this.decorationTitle = "";
      this.decorationSelect = val;
      this.page = 0;
      this.List = [];
      this.finished = false
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    toSearch() {
      this.$router.push("/Search");
    },
    toHome() {
      this.$router.push("/Home");
    },
    menuOpen1() {
      this.drop1 = true;
      if (this.drop1 || this.drop2 || this.drop3) {
        this.home = false;
      }
    },
    menuClose1() {
      this.drop1 = false;
      if (!this.drop1 && !this.drop2 && !this.drop3) {
        this.home = true;
      }
    },
    menuOpen2() {
      this.drop2 = true;
      if (this.drop1 || this.drop2 || this.drop3) {
        this.home = false;
      }
    },
    menuClose2() {
      this.drop2 = false;
      if (!this.drop1 && !this.drop2 && !this.drop3) {
        this.home = true;
      }
    },
    menuOpen3() {
      this.drop3 = true;
      if (this.drop1 || this.drop2 || this.drop3) {
        this.home = false;
      }
    },
    menuClose3() {
      this.drop3 = false;
      if (!this.drop1 && !this.drop2 && !this.drop3) {
        this.home = true;
      }
    },
    toDetail(item) {
      this.$router.push({
        path: "/Detail",
        query: {
          id: item.id
        }
      });
    },
    getAllData() {
      this.$request({
        method: "get",
        url: "/cms/lease/",
        params: {
          page: this.page,
          decoration: this.decorationSelect,
          type: this.typeSelect,
          area: this.areaSelect
        }
      }).then(res => {
        this.$store.commit("setDataLoading", false);
        console.log(res);
        if (res.data && res.data.length) {
          this.hasData = true;
          res.data.forEach(item => {
            if (item.money == 0) {
              if (item.imgs) {
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${item.imgs.split(",")[0]}",
                "address": "${item.address}",
                "money": "面议",
                "area": "${item.area}"
               }`;
                this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              } else {
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${require("@/assets/images/noimg.jpg")}",
                "address": "${item.address}",
                "money": "面议",
                "area": "${item.area}"
               }`;
                this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              }
            } else {
              if (item.imgs) {
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${item.imgs.split(",")[0]}",
                "address": "${item.address}",
                "money": "¥ ${item.money.toFixed(2)}",
                "area": "${item.area}"
               }`;
                this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              } else {
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${require("@/assets/images/noimg.jpg")}",
                "address": "${item.address}",
                "money": "¥ ${item.money.toFixed(2)}",
                "area": "${item.area}"
               }`;
                this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              }
            }
            this.loading = false;
            this.page = this.List[this.List.length - 1].id;
          });
        }else if (!res.data || !res.data.length && this.page == 0){
          //暂无内容
          this.hasData = false;
        } else if (!res.data || res.data.length == 0) {
          this.finished = true;
        }
      });
    },
    onLoad() {
      //上啦加载更多
      this.getAllData();
    }
  }
};
</script>

<style scoped>
.head {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  height: 1.43rem;
  background: #fff;
}
.back {
  margin-left: 0.3rem;
  font-size: 0.55rem;
  color: #999;
  text-align: left;
}
.van-search {
  flex: 1;
  padding: 0.26667rem 0.3rem;
}
.swiper img {
  width: 100%;
  border-radius: 0.15rem;
}
.swiper {
  padding-top: 1.45rem;
  border-radius: 0.15rem;
  margin-top: 0;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.dropmenu {
  position: sticky;
  top: 1.2rem;
  z-index: 4;
}
.dropmenu .van-dropdown-menu {
  border-bottom: 0.02667rem solid #ebedf0;
}
.dropmenu .van-dropdown-menu__bar {
  box-shadow: none !important;
}
.listbd {
  position: relative;
  padding: 0 0.3rem 0.3rem 0.3rem;
}
.listbd li {
  padding: 0.3rem 0;
  border-bottom: 0.02667rem solid #ebedf0;
}
.listbd .listL {
  float: left;
  width: 3.5rem;
  height: 2.4rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
.listbd .listR {
  margin-left: 3.9rem;
  height: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.listbd .listR h2 {
  font-size: 0.4rem;
  font-weight: normal;
  margin-bottom: 0.1rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.listbd .listR h3 {
  color: #999;
  font-size: 0.35rem;
  font-weight: normal;
  margin-bottom: 0.1rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.btdiv .area {
  font-size: 0.35rem;
  color: #777;
}
.btdiv .or {
  color: #f03535;
  font-size: 0.4rem;
  margin-right: 0.22rem;
}
.tohome {
  position: fixed;
  right: 0.1rem;
  bottom: 1rem;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0.05rem 0.1rem 0.3rem rgba(0, 0, 0, 0.07);
  background-image: url(~@/assets/images/home.svg);
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 0.6rem;
  z-index: 1;
}
</style>
